import * as React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { getImage, StaticImage } from "gatsby-plugin-image"

import AtractionItem from "../components/Attractions/AtractionItem"
import AttractionsWrapper from "../components/Attractions/AttractionsWrapper"
import LinkButton from "../components/LinkButton"
import StandardSection from "../components/StandardSection"

import styled from "styled-components"
import { colors } from "../utils"

import landscapeIcon from '../images/icons/landscape.svg'
import skiingIcon from '../images/icons/skiing.svg'
import cityIcon from '../images/icons/city.svg'

import SectionWithImageSlider from "../components/SectionWithImageSlider/SectionWithImageSlider"
import SectionImageSlider from "../components/SectionWithImageSlider/SectionImageSlider"
import SectionText from "../components/SectionWithImageSlider/SectionText"
import { StyledSectionImage } from "../components/SectionWithImageSlider/SectionImage"

import FullPageSlider from "../components/FullPageSlider/FullPageSlider"
import FullPageSliderWrapper from "../components/FullPageSlider/FullPageSliderWrapper"
import FullPageSliderContent from "../components/FullPageSlider/FullPageSliderContent"
import { Helmet } from 'react-helmet'



const StyledActivitiesSection = styled.section`
  background: ${colors.lightBackground};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 50px calc((100% - 1440px) / 2);
  margin: auto;

  & > div {
    display: flex;
    padding: 50px 0;
  }

  .singleItem {
      width: calc(100% / 3);

      p {
        max-width: 250px;
        margin: auto;
      }

      img {
        margin-bottom: 15px;
      }
    }


@media only screen and (max-width: 1000px) {
        & > div{
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .singleItem {
          margin-top: 80px;
          width: 100%;
        }

        .singleItem:first-child {
          margin-top: 0;
        }
    }
    
`

const StyledAdditionalAttractions = styled.div`
color: #989898;
display: flex;
justify-content: space-between;
text-align: left;
max-width: 1100px;
margin: auto;

ul {
  margin: 0;
  padding: 0;
}

ul:first-child {
  margin-left: 20px;
}
li {
  margin-top: 10px;
}

@media only screen and (max-width: 1000px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      ul:first-child {
      margin-left: 0;
    }

    ul {
      list-style: none;
      text-align: center;

      &:last-child {
      li {
        &:last-child {
        &:after {
          display: none;
           }
         }
        }
      }
    }

    li {
      position: relative;
      margin-bottom: 30px;
      margin-top: 0;
    }

    li:after {
      content: '';
      display: block;
      width: 5px;
      height: 5px;
      background: ${colors.primary};
      position: absolute;
      bottom: -17px;
      left: 50%;
      transform: translate(-50%, 50%);
      border-radius: 25%;
    }
    }
`

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>Pokoje gościnne w malowniczej dolinie Wisły - Malince!</title>
        <meta name="description" content="Spędź z nami swój wypoczynek! Okolica pełna ✔ górskich szczytów ✔ wyciągów narciarskich ✔ wielu innych atrakcji!" />
      </Helmet>
      <FullPageSliderWrapper>
        <FullPageSlider images={data.homeBackgrounds.nodes}></FullPageSlider>
        <FullPageSliderContent>
          <h1>Chata pod Jałowcem</h1>
          <h3>Pokoje gościnne w malowniczej<br />dolinie Wisły - Malince!</h3>
        </FullPageSliderContent>
      </FullPageSliderWrapper>


      <SectionWithImageSlider>
        <SectionText>
          <h2>O Nas</h2>
          <section>
            <p>
              Nasz obiekt „Chata pod Jałowcem” położony jest w miejscowości Wisła z pięknym widokiem na dolinę Malinki i otaczające ją pasma górskie.
            </p>
            <p>
              Latem jest to miejsce dla osób, które chcą odpocząć od codziennego zgiełku, blisko lasów i potoków oraz szlaków turystycznych i spacerowych. Zimą doskonałe miejsce dla narciarzy, w pobliżu wyciągi narciarskie Cieńków, Klepki oraz stacje narciarskie w Szczyrku.
            </p>
            <p>
              Oferujemy 6 miejsc noclegowych w dwóch pokojach: 2 i 4 os. z możliwością wynajęcia całości jako studio.
            </p>
          </section>
        </SectionText>
        <SectionImageSlider>
          {data.homeBackgrounds.nodes.map((item, index) => {
            return (
              <StyledSectionImage image={getImage(item)} key={index + 'home_1'} alt=""></StyledSectionImage>
            )
          })}
        </SectionImageSlider>
      </SectionWithImageSlider>


      <StyledActivitiesSection>
        <h2>Spędź z nami aktywnie<br />swój wypoczynek!</h2>
        <div>
          <div className="singleItem">
            <img src={landscapeIcon} alt="Ikona krajobrazu"></img>
            <p>Wspaniała okolica do zdobywania górskich szczytów</p>
          </div>
          <div className="singleItem">
            <img src={skiingIcon} alt="Ikona narciarza"></img>
            <p>Blisko wyciągów narciarskich dla początkujących oraz średnio zaawansowanych</p>
          </div>
          <div className="singleItem">
            <img src={cityIcon} alt="Ikona miasta"></img>
            <p>Niedaleko do centrum Wisły oraz innych miejscowości turystycznych takich jak Szczyrk lub Ustroń</p>
          </div>
        </div>
      </StyledActivitiesSection>


      <StandardSection>
        <h2>Atrakcje w okolicy</h2>
        <AttractionsWrapper>
          <AtractionItem caption="Skocznia Narciarska im. Adama Małysza - ok. 2 km">
            <StaticImage src="../images/skocznia.jpg" alt="" />
          </AtractionItem>
          <AtractionItem caption="Stacja narciarska Cieńków - ok. 500 m">
            <StaticImage src="../images/cienkow.jpg" alt="" />
          </AtractionItem>
          <AtractionItem caption="Basen odkryty i Aquapark W Wiśle centrum - ok. 6/7 km">
            <StaticImage src="../images/baseny.jpg" alt="" />
          </AtractionItem>
          <AtractionItem caption="Zamek prezydenta RP w Wiśle czarnym - ok 5 km">
            <StaticImage src="../images/zameczek.jpg" alt="" />
          </AtractionItem>
        </AttractionsWrapper>

        <h2>Co jeszcze?</h2>

        <StyledAdditionalAttractions>
          <ul>

            <li>Zapora wodna w Czarnym ok.5km</li>
            <li>Przystanek autobusowy ok. 200m</li>
            <li>Restauracja „Malinówka” ok. 500m</li>
          </ul>
          <ul>
            <li>Ośrodek Narciarski Klepki ok.600 m</li>
            <li>Sklepy spożywcze ok. 200 i 300m</li>
            <li>Obiady domowe u Heli ok. 700m</li>
          </ul>
          <ul>
            <li>Ośrodki narciarskie w Szczyrku ok. 10km</li>
            <li>Zajazd u Szewca ok. 300m</li>
          </ul>
        </StyledAdditionalAttractions>
      </StandardSection>

      <StandardSection darkBackground={true}>
        <h2>Wybierz opcję</h2>
        <LinkButton to="/domek" filled={true}>Domek</LinkButton>
        <LinkButton mobileMarginTop={true} to="/apartamenty" filled={true}>Pokoje gościnne</LinkButton>
      </StandardSection>

    </Layout>
  )
}

export const query = graphql`
query {
  homeBackgrounds: allFile (filter: {sourceInstanceName: {eq: "homeBackgrounds"}}, sort:{fields: [name], order:ASC}){
      nodes {
      childImageSharp {
          gatsbyImageData(
              width: 1920
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
          )
      }
      }
    }
  }
`

export default IndexPage
