import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import FullPageSlider from "../components/FullPageSlider/FullPageSlider";
import FullPageSliderWrapper from "../components/FullPageSlider/FullPageSliderWrapper";
import FullPageSliderContent from "../components/FullPageSlider/FullPageSliderContent";

import SectionWithImageSlider from "../components/SectionWithImageSlider/SectionWithImageSlider";
import SectionImageSlider from "../components/SectionWithImageSlider/SectionImageSlider";
import SectionText from "../components/SectionWithImageSlider/SectionText";
import { StyledSectionImage } from "../components/SectionWithImageSlider/SectionImage";
import { getImage } from "gatsby-plugin-image";
import StandardSection from "../components/StandardSection";
import LinkButton from "../components/LinkButton";
import { Helmet } from "react-helmet";
import Gallery from "../components/ImageGallery/Gallery";

const DomekPage = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>
          Chata pod Jałowcem - Pokoje gościnne w malowniczej okolicy Wisły -
          Malinki!
        </title>
        <meta
          name="description"
          content="Idealne miejce dla tych, którzy chcą odpocząć od codziennego zgiełku, blisko lasów, potoków oraz szlaków turystycznych i spacerowych."
        />
      </Helmet>
      <FullPageSliderWrapper>
        <FullPageSlider images={data.domekBackgrounds.nodes}></FullPageSlider>
        <FullPageSliderContent>
          <h1>Chata pod Jałowcem</h1>
          <h3>
            Pokoje gościnne w malowniczej
            <br />
            okolicy Wisły - Malinki!
          </h3>
        </FullPageSliderContent>
      </FullPageSliderWrapper>

      <SectionWithImageSlider>
        <SectionText>
          <h2>Pokoje gościnne „Chata pod Jałowcem”</h2>
          <section>
            <p>
              Nasz obiekt „Chata pod Jałowcem” położony jest w miejscowości
              Wisła z pięknym widokiem na dolinę Malinki i otaczające ją pasma
              górskie.
            </p>
            <p>
              Latem jest to miejsce dla osób, które chcą odpocząć od codziennego
              zgiełku, blisko lasów, potoków oraz szlaków turystycznych i
              spacerowych.
            </p>
            <p>
              Zimą doskonałe miejsce dla narciarzy, w pobliżu wyciągi
              narciarskie Cieńków, Klepki oraz stacje narciarskie w Szczyrku.
            </p>
            <p>
              Oferujemy 6 miejsc noclegowych w dwóch pokojach: 2 i 4 os. z możliwością wynajęcia całości jako studio.
            </p>
          </section>
        </SectionText>
        <SectionImageSlider>
          {data.domekBackgrounds.nodes.map((item, index) => {
            return (
              <StyledSectionImage
                image={getImage(item)}
                key={index + "domek_1"}
                alt=""
              ></StyledSectionImage>
            );
          })}
        </SectionImageSlider>
      </SectionWithImageSlider>

      <StandardSection darkBackground={true}>
        <h2>Opis obiektu</h2>

        <div className="columns">
          <div>
            <p>
              Wszystkie pokoje z łazienkami (prysznic, umywalka, WC ). W każdym
              pokoju szafa, szafka nocna, stolik, telewizor, czajnik
              elektryczny, bezpłatny internet światłowodowy.
            </p>
            <p>
              W pokoju 2-osobowym: łóżko małżeńskie, w pokoju 4 osobowym: łóżko małżeńskie, łóżko
              piętrowe i fotel rozkładany.
            </p>
            <p>
              Ogólnodostępny aneks kuchenny wyposażony: kuchenka indukcyjna,
              mikrofalówka, lodówko-zamrażarka, przybory kuchenne (garnki,
              kubki, talerze, sztućce itp.)
            </p>
          </div>

          <div>
            <p>
              Wyjście niezależne na taras, gdzie można skorzystać z przenośnego
              grilla, wypocząć na ogrodowej huśtawce a dzieci w piaskownicy.
            </p>
            <p>
              W obiekcie obowiązuje całkowity zakaz palenia ale jest wyznaczone
              miejsce na zewnątrz budynku.
            </p>
            <p>Parking przy ulicy.</p>
          </div>
        </div>
      </StandardSection>

      <StandardSection>
        <h2>Ważne informacje</h2>
        <p>
          Cena: 60 zł / osoba / noc przy min. 3 dobach
          <br />
          Minimalny czas wynajmu: 2 doby
        </p>
        <p>
          Rezerwacja telefoniczna:
          <br />
          +48 500 026 168
          <br />
          +48 604 942 921
        </p>
        <p>
          Potwierdzenie rezerwacji jest wpłata zadatku na konto:
          <br />
          Bank PKO BP
          20&nbsp;1020&nbsp;1390&nbsp;0000&nbsp;6102&nbsp;0082&nbsp;3187
        </p>
        <p>
          Przedpłata: obiekt pobiera zadatek w wysokości 30 % kwoty za pobyt.
          <br />
          Zadatek należy wpłacić w ciągu 2 dni od dokonania rezerwacji.
          <br />
          Zameldowanie od 14:00 w dniu przyjazdu, wymeldowanie do 10:00 w dniu
          wyjazdu.
        </p>
        <p>
          Płatność tylko przelewem lub Blikiem!
          <br />
          Opłata miejscowa w wysokości 3zł/osoba/doba jest pobierana na miejscu gotówką.
          <br />
        </p>
        <LinkButton to="/kontakt" filled={true} marginTop={true}>
          Zapytaj o dostępność
        </LinkButton>
      </StandardSection>

      <StandardSection>
        <h2 className="noTopMargin">Galeria</h2>

        <Gallery items={data.domekGallery.nodes} />
      </StandardSection>
    </Layout>
  );
};

export const query = graphql`
  query {
    domekBackgrounds: allFile(
      filter: { sourceInstanceName: { eq: "homeBackgrounds" } }
      sort: { fields: [name], order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
    domekGallery: allFile(
      filter: { sourceInstanceName: { eq: "apartamentyGallery" } }
      sort: { fields: [name], order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
`;

export default DomekPage;
